/* You can add global styles to this file, and also import other style files */
.bold {
  font-weight: bold;
}

.order-calculator-value-fix  {
  margin-left: 10px;
}

.native-textarea.sc-ion-textarea-ios {
  padding: 6px;
}

.assistant {
  bottom: 80px !important;
  right: 16px !important;
  max-width: calc(100vw - 32px) !important;
  max-height: calc(100vh - 120px) !important;
  z-index: 1 !important;
}

.input-clear-icon.sc-ion-input-md {
  background-image: url("data:image/svg+xml;charset=utf-8,<svg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20512%20512'><path%20fill='var(--ion-color-step-600,%20%23666666)'%20d='M403.1,108.9c-81.2-81.2-212.9-81.2-294.2,0s-81.2,212.9,0,294.2c81.2,81.2,212.9,81.2,294.2,0S484.3,190.1,403.1,108.9z%20M352,340.2L340.2,352l-84.4-84.2l-84,83.8L160,339.8l84-83.8l-84-83.8l11.8-11.8l84,83.8l84.4-84.2l11.8,11.8L267.6,256L352,340.2z'/></svg>");
}
