// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import './animations';

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('assets/fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
  url('assets/fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('assets/fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
  url('assets/fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('assets/fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
  url('assets/fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('assets/fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('assets/fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
  url('assets/fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('assets/fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}



html {
  .md,
  .ios,
  body {
    font-family: 'Roboto', serif;
  }

  body {
    color: rgb(84, 83, 83);
  }
}

.clickable {
  cursor: pointer;
}

.accordion-header {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
}


.btn-col {
  display: flex;
  align-items: flex-end;

  ion-item {
    flex-grow: 1;
  }

  ion-button {
    flex-shrink: 0;
  }
}

.buttons {
  &__success {
    color: rgb(33, 227, 138);
  }
}

ion-content {
  display: block;
  position: relative;
}

ion-select,
ion-datetime {
  --padding-start: 6px;
}

.variants-bar {
  height: 60px;
  background: rgb(210, 218, 229);
}

.fixed-position-action-control-bar {
  z-index: 1000;
  width: 100%;
  background: rgba(247, 248, 249, 0.98);
}


ion-button.fa-icon-ion-button {
  font-size: 30px;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 45px;
  }
}

.search-card {
  background: white;
  border-bottom: 1px solid rgb(222, 228, 236);
}

.is-card-selected {
  background: rgb(205, 218, 229) !important;
}

.alternate-row-background  {
  background: rgba(247, 248, 249, 0.98);
}

.action-icon  {
  &:hover  {
    opacity: 0.7;
  }
}

.content {
  &__body {
    max-width: 800px;
    margin: 0 auto 22px auto;
  }

  &__padding {
    --padding-top: 0px;
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-bottom: 20px;

    &--mobile {
      --padding-top: 0px;
      --padding-start: 0;
      --padding-end: 0;
      --padding-bottom: 0;
    }
  }

  &__spacer {
    padding-top: 30px;
  }

  &__spacer-double {
    padding-top: 60px;
  }
}

.selectable-text {
  user-select: all;
}


.ion-color-active {
  color: white;
  background: var(--common-badge-color);
}

.radio-chip {
  border-radius: 4px;
}

.medium-icon {
  font-size: 1.5em;
}
